<template>
    <div v-if="!isLoadingBil">
        <section class="aa-contenedor-basico fade-in">
            <div class="aa-contenedor-basico-portada">
                <div class="portada">

                </div>
                
            </div>

            <div class="aa-cotenedor-basico-cuerpo">
                <h2 class="aa-text-h2 my-2"> Clases </h2>
                <div class="card-clase mb-2 mt-1 mx-1 p-1 text-start" v-for="clase in bilingue.clases" :key="clase.id">
                    <div class="card-clase-img">
                        <img src="../../../assets/img/book.svg" title="Asignatura" alt="Icono libro" />
                    </div>
                    <div class="card-clase-cuerpo">
                        <h5 class="m-0">{{clase.asignatura}}</h5>
                        <h6 class="m-0">{{clase.profesor}}</h6>
                        <small class="m-0">
                            <span v-for="curso in clase.cursos" :key="curso"> {{curso}} </span>
                        </small>
                    </div>
                </div>
            </div>


        </section>
    </div>
    <div v-else>
        <PrensaSk />
    </div>
</template>

<script>

import useBilin from '../composables/useBilin'
import PrensaSk from '../components/skeleton/PrensaSk.vue'


export default {
    components: { PrensaSk },
    setup() {
        const { isLoadingBil, loaded, bilingue, getClases } = useBilin()
        getClases()
        return {
            isLoadingBil,
            bilingue,
            loaded,
            onLoaded: () => loaded.value = true,
        }
    }
}
</script>

<style lang="sass" scoped>
.card-clase
    $root:&
    
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: flex-start
    background-color: #cae7fd80
    border-radius: 16px
    align-items: flex-start
    gap: 10px
    align-content: flex-start
    box-shadow: 0px 5px 12px 0px rgb(110, 192, 255, 0.32)

    &-img
        border-radius: 50%
        background-color: #fff
        img
            width: 30px
            padding: 10px

    &-cuerpo
        flex: 1
        h5
            font-weight: 700
            font-size: 1rem
        h6
            color: #000
            font-weight: 600
            font-size: 0.8rem
        small
            font-size: 0.7rem
            color: #545454
            span
                margin-right: 10px
                margin-bottom: 5px
                display: inline-block
        
.portada
    background-image: url('../../../assets/img/fotos/clases.jpg')
</style>